import React from 'react';
import MaterialTable from 'material-table';

const MyTable = (props) => {
  const {
    history,
    columns,
    data,
    title,
    addUrl,
    setOpen,
    setTempData,
    editUrl,
    editKey,
    viewOnly,
    disableAction,
    isFilter,
  } = props;

  const addButton = {
    icon: 'add',
    tooltip: `Tambah ${title}`,
    isFreeAction: true,
    onClick: (event) => {
      history.replace(addUrl);
    },
  };

  const filterButton = {
    icon: 'filter',
    tooltip: `Tambah ${title}`,
    isFreeAction: true,
    onClick: (event) => {},
  };

  const editButton = {
    icon: 'edit',
    tooltip: `Edit ${title}`,
    onClick: (event, rowData) => {
      history.replace(`${editUrl}/${rowData[editKey]}`);
    },
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        actions={[
          editUrl && !disableAction === true ? { ...editButton } : null,
          viewOnly
            ? null
            : {
                icon: 'chevron_right',
                tooltip: `Detail ${title}`,
                onClick: (event, rowData) => {
                  setOpen(true);
                  setTempData(rowData);
                },
              },
          addUrl && !disableAction === true ? { ...addButton } : null,
          isFilter && { ...filterButton },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        title={`Data ${title}`}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} dari {count}',
            labelRowsSelect: 'Baris Data',
            firstAriaLabel: 'Halaman Pertama',
            firstTooltip: 'Halaman Pertama',
            previousAriaLabel: 'Halaman Sebelumnya',
            previousTooltip: 'Halaman Sebelumnya',
            nextAriaLabel: 'Halaman Selanjutnya',
            nextTooltip: 'Halaman Selanjutnya',
            lastAriaLabel: 'Halaman Terakhir',
            lastTooltip: 'Halaman Terakhir',
          },
          header: {
            actions: 'Aksi',
          },
          body: {
            emptyDataSourceMessage: 'Data tidak ditemukan',
            filterRow: {
              filterTooltip: 'Saring',
            },
          },
          toolbar: {
            searchTooltip: 'Cari',
            searchPlaceholder: 'Cari',
          },
        }}
      />
    </div>
  );
};

export default MyTable;
