import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { UNITS } from '../../graphql/Unit';
import MyModal from '../MyModal';
import MyTable from '../MyTable';

const UnitPage = (props) => {
  const { query, history, role } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [satuan, setSatuan] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Nama', field: 'name' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      width: 500,
      type: 'datetime',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setGlobalLoading(true);
    const getUnits = async () => {
      const {
        data: { units },
      } = await query({
        query: UNITS,
        fetchPolicy: 'no-cache',
      });
      setSatuan(units);
      setGlobalLoading(false);
    };

    getUnits();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={satuan}
        title="Satuan"
        addUrl="/satuan/new"
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl="/satuan/edit"
        editKey="unid"
        disableAction={role !== 'ADMIN' ? true : false}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Satuan"
        mappingRows={{
          unid: 'ID Satuan',
          name: 'Nama',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default UnitPage;
