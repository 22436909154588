import { gql } from '@apollo/client';

export const SUPPLIERS = gql`
  query suppliers {
    suppliers {
      id
      sid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const FIND_SUPPLIER_BY_SID = gql`
  query findSupplierBySid($sid: String!) {
    findSupplierBySid(sid: $sid) {
      id
      sid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation createSupplier($data: InSupplier!) {
    createSupplier(data: $data) {
      id
      sid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier($sid: String!, $data: InSupplier!) {
    updateSupplier(sid: $sid, data: $data) {
      id
      sid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;
