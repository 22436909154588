import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { OUTGOING_STOCKS } from '../../graphql/OutgoingStock';
import MyTable from '../MyTable';

const OutgoingStockPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [outgoingStocks, setOutgoingStocks] = useState([]);

  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
    },
    { title: 'Tanggal', field: 'date' },
    { title: 'Nama Produk', field: 'product.name' },
    { title: 'Stok Awal', field: 'history.last_stock' },
    { title: 'Stok Keluar', field: 'stock_quantity' },
    {
      title: 'Stok Akhir',
      render: (rowData) => {
        return rowData.history.last_stock - rowData.stock_quantity;
      },
    },
    {
      title: 'Harga Awal',
      render: (rowData) => {
        return (
          <>
            Rp.{' '}
            {rowData.history.selling_price.toLocaleString('id-ID', {
              minimumFractionDigits: 0,
            })}
          </>
        );
      },
    },
    {
      title: 'Harga Akhir',
      render: (rowData) => {
        return (
          <>
            Rp.{' '}
            {rowData.price.toLocaleString('id-ID', {
              minimumFractionDigits: 0,
            })}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setGlobalLoading(true);
    const getUnits = async () => {
      const {
        data: { outgoingStocks },
      } = await query({
        query: OUTGOING_STOCKS,
        fetchPolicy: 'no-cache',
      });
      setOutgoingStocks(outgoingStocks);
    };

    getUnits();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={outgoingStocks}
        title="Barang Keluar"
        addUrl="/outgoing_stock/new"
        // disableAction={true}
        viewOnly
      />
    </div>
  );
};

export default OutgoingStockPage;
