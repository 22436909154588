import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import { DASHBOARD } from '../../graphql/Product';

import {
  TrackChanges as TrackChangesIcon,
  BorderColor as BorderColorIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
} from '@material-ui/icons/';
const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
    height: 125,
  },
  fixedHeight: {
    height: 200,
  },
}));

const UserDashboard = (props) => {
  const { query } = props;
  const classes = useStyles();
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    const getDashboard = async () => {
      const {
        data: { dashboard },
      } = await query({
        query: DASHBOARD,
        fetchPolicy: 'no-cache',
      });
      setDashboard(dashboard);
    };

    getDashboard();
  }, [query]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.paper}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    {dashboard?.total_barcode || 0}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Total Barang Barcode
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <TrackChangesIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.paper}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    {dashboard?.total_non_barcode || 0}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Total Barang Non Barcode
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <BorderColorIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.paper}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    {dashboard?.total_sales || 0}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Total Penjualan
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <CheckCircleOutlineIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.paper}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    {dashboard?.total_product_sales?.name} -{' '}
                    {dashboard?.total_product_sales?.total} Qty
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Barang Paling Banyak Terjual
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <PlaylistAddCheckIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UserDashboard;
