import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyBreadcrumbs from '../MyBreadcrumbs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  CREATE_UNIT,
  FIND_UNIT_BY_UNID,
  UPDATE_UNIT,
} from '../../graphql/Unit';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const UnitForm = (props) => {
  const { history, query, mutate, match, isEdit } = props;
  const classes = usetStyles();
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [unit, setUnit] = useState({});

  useEffect(() => {
    if (isEdit && match.params.unid) {
      setGlobalLoading(true);
      const getUnit = async () => {
        const {
          data: { findUnitByUnid },
        } = await query({
          query: FIND_UNIT_BY_UNID,
          variables: {
            unid: match.params.unid,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setUnit(findUnitByUnid);
      };

      getUnit();
    }
  }, [isEdit, match.params.unid, query, setGlobalLoading]);

  const AddPage = () => (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Satuan', url: '/satuan' }}
        tertiary={{ title: isEdit ? 'Ubah' : 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              name: isEdit ? unit.name : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({ name }, { resetForm }) => {
              try {
                setGlobalLoading(true);

                if (isEdit) {
                  const {
                    data: { updateUnit },
                  } = await mutate({
                    mutation: UPDATE_UNIT,
                    variables: { unid: unit.unid, name },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Satuan ${updateUnit.name} berhasil diubah!`,
                    opened: true,
                  });
                } else {
                  const {
                    data: { createUnit },
                  } = await mutate({
                    mutation: CREATE_UNIT,
                    variables: { name },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Satuan ${createUnit.name} berhasil disimpan!`,
                    opened: true,
                  });
                }

                history.replace('/satuan');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nama Satuan"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/satuan')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <AddPage />;
};

export default UnitForm;
