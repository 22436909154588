import React from 'react';

export class SellingReportPDF extends React.Component {
  constructor(props) {
    super(props);

    const sub_total = props.data?.order_products.reduce(
      (n, { sub_total }) => n + sub_total,
      0,
    );

    this.state = {
      data: props.data,
      sub_total,
    };
  }
  render() {
    const mystyle = {
      margin: '0 0 0 0',
      fontSize: '11pt',
    };
    let discount = 0;
    let total = 0;
    if (this.state.data.discount) {
      discount = (this.state.sub_total * this.state.data.discount) / 100;
      total = this.state.sub_total - discount;
    }

    return (
      <div
        className='print-source'
        style={{
          fontFamily: 'Courier',
        }}
      >
        <p
          style={{ textAlign: 'center', fontSize: '16pt', marginBottom: '0px' }}
        >
          ----------------------------------------------
          <br />
          Toko {process.env.REACT_APP_NAME}
          <br />
          {process.env.REACT_APP_NAME === 'Bagus Jaya'
            ? 'Jalan Dukuh Bangsri RT 7 RW 03'
            : 'Sukodono'}
          <br />
        </p>
        <p style={mystyle}>{this.state.data.created_at}</p>
        <p style={mystyle}>No {this.state.data.id}</p>
        <p style={mystyle}>Nama Kasir: {this.state.data.user.name}</p>
        <table
          width='100%'
          border='0'
          style={{ fontSize: '14pt', textAlign: 'left' }}
        >
          {this.state.data.order_products.length &&
            this.state.data.order_products.map((x, i) => (
              <>
                <tr>
                  <td colSpan={2}>
                    {i + 1}. {x.product.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    {x?.quantity} x{' '}
                    {/* {x?.discount !== 0 && (
                      <p
                        style={{
                          textDecoration: 'line-through',
                          display: 'inline',
                        }}
                      >
                        Rp.{' '}
                        {x.discount_price?.toLocaleString('id-ID', {
                          minimumFractionDigits: 0,
                        })}
                      </p>
                    )}{' '} */}
                    {x.price.toLocaleString('id-ID', {
                      minimumFractionDigits: 0,
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  </td>
                  <td>
                    {x.sub_total.toLocaleString('id-ID', {
                      minimumFractionDigits: 0,
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  </td>
                </tr>
              </>
            ))}
          <tr>
            <td colspan='3'>
              <p style={{ textAlign: 'center', fontSize: '16pt' }}>
                ----------------------------------------------
              </p>
            </td>
          </tr>
          <tr>
            <td>Total Item: {this.state.data.order_products.length}</td>
            <td></td>
          </tr>
          <tr>
            <td>Sub Total</td>
            <td>
              {this.state?.sub_total?.toLocaleString('id-ID', {
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'IDR',
              })}
            </td>
          </tr>
          {discount ? (
            <>
              <tr>
                <td>Diskon ({this.state?.data?.discount}%)</td>
                <td>
                  {discount?.toLocaleString('id-ID', {
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'IDR',
                  })}
                </td>
              </tr>
              <tr style={{ fontWeight: 'bold' }}>
                <td>Total</td>
                <td>
                  {total?.toLocaleString('id-ID', {
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'IDR',
                  })}
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <td>Bayar</td>
            <td>
              {this.state?.data?.amount_paid?.toLocaleString('id-ID', {
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'IDR',
              })}
            </td>
          </tr>
          <tr>
            <td>Kembali</td>
            <td>
              {this.state?.data?.change?.toLocaleString('id-ID', {
                minimumFractionDigits: 0,
                style: 'currency',
                currency: 'IDR',
              })}
            </td>
          </tr>
        </table>
      </div>
    );
  }
}
