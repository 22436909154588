import React, { useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloConsumer,
} from '@apollo/client';
import './App.css';
import { GlobalProvider } from './utils/GlobalContext.js';
import MyRouter from './components/MyRouter';

const uri =
  process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_GRAPHQL_URL_LOCAL
    : process.env.REACT_APP_GRAPHQL_URL_PRODUCTION;

<pre>{process.env.REACT_APP_NAME}</pre>;
const client = new ApolloClient({
  uri,
  cache: new InMemoryCache(),
});

function App() {
  const [snack, setSnack] = useState({
    variant: 'success',
    message: null,
    opened: false,
  });
  const [globalLoading, setGlobalLoading] = useState(false);

  return (
    <GlobalProvider value={{ setSnack, setGlobalLoading }}>
      <ApolloProvider client={client}>
        <ApolloConsumer>
          {(client) => (
            <MyRouter
              client={client}
              snack={snack}
              globalLoading={globalLoading}
            />
          )}
        </ApolloConsumer>
      </ApolloProvider>
    </GlobalProvider>
  );
}

export default App;
