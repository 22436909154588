import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@material-ui/core';

import { ComponentToPrint } from './Barcode';

const Example = (props) => {
  const { barcode, name, selling_price } = props;
  const componentRef = useRef();
  const pageStyle = `
    @page {
      size: 40mm 20mm;
      margin: 0mm !important
    }
  `;

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" color="primary">
            Cetak
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle={pageStyle}
        documentTitle={`Cetak Barcode ${name}`}
      />
      <ComponentToPrint
        barcode={barcode}
        name={name}
        selling_price={selling_price}
        ref={componentRef}
      />
    </div>
  );
};

export default Example;
