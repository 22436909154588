import React, { useContext } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'universal-cookie/cjs';

import { AUTHENTICATE } from '../graphql/User';
import GlobalContext from '../utils/GlobalContext';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const { query, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const classes = useStyles();

  const handleKeyDown = (e, handleSubmit) => {
    if (e.which === 13) {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Halaman Login Toko {process.env.REACT_APP_NAME}
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Required').email('Invalid email'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={async ({ email, password }) => {
            try {
              setGlobalLoading(true);
              const {
                data: { authenticate },
              } = await query({
                query: AUTHENTICATE,
                variables: { email, password },
                fetchPolicy: 'no-cache',
              });
              const cookie = new Cookies();
              cookie.set('id', authenticate.id);
              cookie.set('uid', authenticate.uid);
              cookie.set('email', authenticate.email);
              cookie.set('role', authenticate.role);
              cookie.set('token', authenticate.token, { path: '/' });
              debugger;
              history.replace('/dashboard');

              setGlobalLoading(false);
              setSnack({
                variant: 'success',
                message: `Selamat Datang ${authenticate.email}`,
                opened: true,
              });
            } catch (error) {
              setGlobalLoading(false);
              setSnack({
                variant: 'error',
                message: 'Email atau Password tidak valid!',
                opened: true,
              });
            }
          }}
        >
          {({ errors, handleSubmit, handleChange, values }) => (
            <form className={classes.form} noValidate>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
                autoFocus
                onChange={handleChange}
                value={values.email}
                onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={handleChange}
                value={values.password}
                onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
              />
              <Button
                onClick={handleSubmit}
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Masuk
              </Button>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
