import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyBreadcrumbs from '../MyBreadcrumbs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  CREATE_SUPPLIER,
  FIND_SUPPLIER_BY_SID,
  UPDATE_SUPPLIER,
} from '../../graphql/Supplier';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const SupplierForm = (props) => {
  const { history, query, mutate, match, isEdit } = props;
  const classes = usetStyles();
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    if (isEdit && match.params.sid) {
      setGlobalLoading(true);
      const getUnit = async () => {
        const {
          data: { findSupplierBySid },
        } = await query({
          query: FIND_SUPPLIER_BY_SID,
          variables: {
            sid: match.params.sid,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setSupplier(findSupplierBySid);
      };

      getUnit();
    }
  }, [isEdit, match.params.sid, query, setGlobalLoading]);

  const AddPage = () => (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Pemasok', url: '/pemasok' }}
        tertiary={{ title: isEdit ? 'Ubah' : 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              name: isEdit ? supplier.name : '',
              address: isEdit ? supplier.address : '',
              phone: isEdit ? supplier.phone : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
              address: Yup.string().required('Harus diisi'),
              phone: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({ name, address, phone }, { resetForm }) => {
              try {
                setGlobalLoading(true);
                debugger;
                if (isEdit) {
                  const {
                    data: { updateSupplier },
                  } = await mutate({
                    mutation: UPDATE_SUPPLIER,
                    variables: {
                      sid: supplier.sid,
                      data: {
                        name,
                        address,
                        phone,
                      },
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });
                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Pemasok ${updateSupplier.name} berhasil diubah!`,
                    opened: true,
                  });
                } else {
                  const {
                    data: { createSupplier },
                  } = await mutate({
                    mutation: CREATE_SUPPLIER,
                    variables: {
                      data: {
                        name,
                        address,
                        phone,
                      },
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });
                  debugger;
                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Pemasok ${createSupplier.name} berhasil disimpan!`,
                    opened: true,
                  });
                }

                history.replace('/pemasok');
              } catch (error) {
                debugger;
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nama"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="No. Handphone"
                        name="phone"
                        onChange={handleChange}
                        value={values.phone}
                      />
                      {errors.phone && touched.phone && (
                        <div style={{ color: 'red' }}>{errors.phone}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="address"
                        label="Alamat"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        multiline
                        rowsMax={3}
                        rows={3}
                      />
                      {errors.address && touched.address && (
                        <div style={{ color: 'red' }}>{errors.address}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/pemasok')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <AddPage />;
};

export default SupplierForm;
