import { gql } from '@apollo/client';

export const CREATE_OUTGOING_STOCK = gql`
  mutation createOutgoingStock($data: InOutgoingStock!) {
    createOutgoingStock(data: $data) {
      product_id
      price
      stock_quantity
      notes
      history
      created_at
      updated_at
      date
    }
  }
`;

export const OUTGOING_STOCKS = gql`
  query outgoingStocks {
    outgoingStocks {
      id
      product_id
      price
      stock_quantity
      notes
      history
      created_at
      updated_at
      date
      product {
        id
        name
        last_stock
      }
    }
  }
`;
