import React from 'react';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

const MyBreadcrumbs = (props) => {
  const { history, primary, secondary, tertiary } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        href={primary.url}
        onClick={() => history.replace(primary.url)}
      >
        {primary.title}
      </Link>
      <Link
        color="inherit"
        href={secondary.url}
        onClick={() => history.replace(secondary.url)}
      >
        {secondary.title}
      </Link>
      <Typography color="textPrimary">{tertiary.title}</Typography>
    </Breadcrumbs>
  );
};

export default MyBreadcrumbs;
