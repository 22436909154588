import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core/';
import { SellingReportPDF } from '../Report/SellingReportPDF';
import ReactToPrint from 'react-to-print';

const DialogPrint = (props) => {
  const { open, handleClose, data, setOpen } = props;
  const componentRef = useRef();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          Cetak Bukti Pembayaran
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Transaksi berhasil, apakah ingin mencetak bukti pembayaran?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tutup
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="outlined" color="primary">
                Cetak
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`Cetak Barcode`}
            onAfterPrint={(e) => {
              window.close();
              setOpen(false);
              window.location.reload();
            }}
          />
          <SellingReportPDF data={data} ref={componentRef} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogPrint;
