import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';

import MyBreadcrumbs from '../MyBreadcrumbs';
import { PRODUCTS } from '../../graphql/Product';
import { CREATE_OUTGOING_STOCK } from '../../graphql/OutgoingStock';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 25,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="Rp. "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const OutgoingStockPage = (props) => {
  const { query, mutate } = props;
  const [tempData, setTempData] = useState({});
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    value: '',
    error: false,
    errorText: 'Harus diisi',
  });
  const [quantity, setQuantity] = useState({
    value: 0,
    error: false,
    errorText: 'Harus diisi',
  });
  const [note, setNote] = useState({
    value: '',
    error: false,
    errorText: 'Harus diisi',
  });
  const [price, setPrice] = useState({
    value: 0,
    error: false,
    errorText: 'Harus diisi',
  });
  const [date, setDate] = useState({
    value: dayjs().format('YYYY-MM-DD'),
    error: false,
    errorText: 'Harus diisi',
  });
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const classes = usetStyles();

  useEffect(() => {
    setGlobalLoading(true);
    const getProducts = async () => {
      const {
        data: { products },
      } = await query({
        query: PRODUCTS,
        fetchPolicy: 'no-cache',
      });

      setProducts(products);
    };

    getProducts();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  const filterOptions = createFilterOptions({
    stringify: ({ name, barcode }) => `${name} ${barcode}`,
  });

  const handleSave = async () => {
    if (!product.value) {
      setProduct({ ...product, error: true });
    }

    if (!quantity.value) {
      setQuantity({ ...quantity, error: true });
    }

    const data = {
      product_id: Number(product.value),
      price: Number(price.value),
      stock_quantity: Number(quantity.value),
      notes: note.value,
      history: JSON.stringify(tempData),
      date: date.value,
    };

    try {
      setGlobalLoading(true);
      const {
        data: { createOutgoingStock },
      } = await mutate({
        mutation: CREATE_OUTGOING_STOCK,
        variables: { data },
        fetchPolicy: 'no-cache',
        onError: (error) => {
          console.log('==> Error execute mutation', error);
        },
      });

      if (createOutgoingStock) {
        setGlobalLoading(false);
        setSnack({
          variant: 'success',
          message: 'Data Barang Keluar Berhasil Disimpan',
          opened: true,
        });

        window.location.reload();
      }
    } catch (error) {
      setGlobalLoading(false);
      setError(setSnack, error);
    }
  };

  return (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Stok Keluar ', url: '/outgoing_stock' }}
        tertiary={{ title: 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  filterOptions={filterOptions}
                  onChange={(_, newValue) => {
                    const product = products.find((x) => x.id === newValue.id);

                    setProduct({
                      value: newValue.id,
                      error: false,
                      errorText: '',
                    });
                    setTempData({
                      id: product.id,
                      name: product.name,
                      selling_price: product.selling_price,
                      last_stock: product.last_stock,
                      unit: product.unit.name,
                      barcode: product.barcode,
                    });
                    setPrice({
                      ...price,
                      value: product.selling_price,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nama Produk atau Barcode"
                      variant="outlined"
                      autoFocus
                    />
                  )}
                />
                {product.error && (
                  <div style={{ color: 'red' }}>{product.errorText}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Tanggal Stok Keluar"
                    value={date?.value}
                    onChange={(e) => {
                      const date = dayjs(e).format('YYYY-MM-DD');

                      setDate({
                        value: date,
                        error: false,
                        errorText: '',
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Nama Produk"
                  name="name"
                  disabled
                  value={tempData?.name}
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="barcode"
                  label="Barcode"
                  name="barcode"
                  disabled
                  value={tempData?.barcode}
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Harga Jual"
                  value={price.value}
                  name="selling_price"
                  id="selling_price"
                  onChange={(e) =>
                    setPrice({
                      value: e.target.value,
                      error: false,
                      errorText: '',
                    })
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Stok Awal"
                  value={tempData?.last_stock}
                  name="last_stock"
                  id="last_stock"
                  disabled
                  type="number"
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Satuan"
                  value={tempData?.unit}
                  name="unit"
                  id="unit"
                  disabled
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Stok Keluar"
                  value={quantity.value}
                  onChange={(e) =>
                    setQuantity({
                      value: e.target.value,
                      error: false,
                      errorText: '',
                    })
                  }
                  name="quantity"
                  id="quantity"
                  type="number"
                />
                {quantity.error && (
                  <div style={{ color: 'red' }}>{quantity.errorText}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="notes"
                  label="Keterangan"
                  name="notes"
                  onChange={(e) =>
                    setNote({
                      value: e.target.value,
                      error: false,
                      errorText: '',
                    })
                  }
                  value={note.value}
                  multiline
                  rowsMax={3}
                  rows={3}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="baseline"
              >
                <Button
                  onClick={() => handleSave()}
                  variant="outlined"
                  color="primary"
                  style={{
                    marginRight: 10,
                  }}
                >
                  Tambah
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default OutgoingStockPage;
