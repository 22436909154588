import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MyBreadcrumbs from '../MyBreadcrumbs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import {
  CREATE_PRODUCT,
  FIND_PRODUCT_BY_PID,
  UPDATE_PRODUCT,
} from '../../graphql/Product';
import { SEARCH_SUB_CATEGORIES } from '../../graphql/SubCategory';
import { SEARCH_UNIT } from '../../graphql/Unit';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="Rp. "
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ProductForm = (props) => {
  const { history, query, mutate, match, isEdit } = props;
  const classes = usetStyles();
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [product, setProduct] = useState({});
  let subCategoryOption = [];
  let unitOption = [];

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  const getCategories = async ({ name }) => {
    const {
      data: { searchSubCategories },
    } = await query({
      query: SEARCH_SUB_CATEGORIES,
      variables: { name },
      fetctPolicy: 'no-cache',
    });

    const options = searchSubCategories.map((l) => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const getUnits = async ({ name }) => {
    const {
      data: { searchUnits },
    } = await query({
      query: SEARCH_UNIT,
      variables: { name },
      fetctPolicy: 'no-cache',
    });

    const options = searchUnits.map((l) => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const loadOptions = async (inputValue, type) => {
    if (type === 'sub_categories') {
      return getCategories({
        name: inputValue,
      });
    }

    if (type === 'units') {
      return getUnits({
        name: inputValue,
      });
    }

    return null;
  };

  useEffect(() => {
    if (isEdit && match.params.pid) {
      setGlobalLoading(true);
      const getUnit = async () => {
        const {
          data: { findProductByPid },
        } = await query({
          query: FIND_PRODUCT_BY_PID,
          variables: {
            pid: match.params.pid,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setProduct(findProductByPid);
      };

      getUnit();
    }
  }, [isEdit, match.params.pid, query, setGlobalLoading]);

  if (isEdit) {
    subCategoryOption.push({
      value: product?.sub_category?.id,
      label: product?.sub_category?.name,
    });
    unitOption.push({
      value: product?.unit?.id,
      label: product?.unit?.name,
    });
  }

  const AddPage = () => (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Produk', url: '/produk' }}
        tertiary={{ title: isEdit ? 'Ubah' : 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              name: isEdit ? product.name : '',
              sub_category: isEdit ? product.sub_category_id : '',
              unit: isEdit ? product.unit_id : '',
              purchase_price: isEdit ? product.purchase_price : '',
              selling_price: isEdit ? product.selling_price : '',
              first_stock: isEdit ? product.first_stock : '',
              last_stock: isEdit ? product.last_stock : '',
              minimal_stock: isEdit ? product.minimal_stock : '',
              barcode: isEdit ? product.barcode : '',
              wholesale_price: isEdit ? product.wholesale_price : '',
              expiry_date: isEdit ? product.expiry_date : new Date(),
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
              purchase_price: Yup.number().required('Harus diisi'),
              selling_price: Yup.number().required('Harus diisi'),
              first_stock: Yup.number().required('Harus diisi'),
              last_stock: Yup.number().required('Harus diisi'),
              minimal_stock: Yup.number().required('Harus diisi'),
              wholesale_price: Yup.number().required('Harus diisi'),
            })}
            onSubmit={async (
              {
                name,
                category_id,
                sub_category,
                unit,
                purchase_price,
                selling_price,
                first_stock,
                last_stock,
                minimal_stock,
                barcode,
                wholesale_price,
                expiry_date,
              },
              { resetForm }
            ) => {
              try {
                setGlobalLoading(true);

                if (isEdit) {
                  const data = {
                    sub_category_id:
                      typeof sub_category === 'object'
                        ? sub_category.value
                        : sub_category,
                    unit_id: typeof unit === 'object' ? unit.value : unit,
                    name,
                    purchase_price: Number(purchase_price),
                    selling_price: Number(selling_price),
                    first_stock: Number(first_stock),
                    last_stock: Number(last_stock),
                    minimal_stock: Number(minimal_stock),
                    barcode,
                    wholesale_price: Number(wholesale_price),
                    is_barcode: true,
                    expiry_date: expiry_date,
                  };

                  const {
                    data: { updateProduct },
                  } = await mutate({
                    mutation: UPDATE_PRODUCT,
                    variables: {
                      pid: product.pid,
                      data,
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Produk ${updateProduct.name} berhasil diubah!`,
                    opened: true,
                  });
                } else {
                  const data = {
                    sub_category_id: sub_category.value,
                    unit_id: unit.value,
                    name,
                    purchase_price: Number(purchase_price),
                    selling_price: Number(selling_price),
                    first_stock: Number(first_stock),
                    last_stock: Number(last_stock),
                    minimal_stock: Number(minimal_stock),
                    barcode,
                    wholesale_price: Number(wholesale_price),
                    is_barcode: true,
                    expiry_date: expiry_date,
                  };

                  const {
                    data: { createProduct },
                  } = await mutate({
                    mutation: CREATE_PRODUCT,
                    variables: { data },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Produk ${createProduct.name} berhasil disimpan!`,
                    opened: true,
                  });
                }

                history.replace('/produk');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel shrink className={classes.labelAsyncSelect}>
                        Nama Sub Kategori *
                      </InputLabel>

                      <AsyncSelect
                        id="sub_category"
                        margin="normal"
                        name="sub_category"
                        placeholder="Nama Sub Kategori"
                        loadOptions={(e) => loadOptions(e, 'sub_categories')}
                        defaultOptions
                        defaultValue={isEdit && subCategoryOption}
                        values={values.sub_category}
                        onInputChange={(e) => handleInputChange(e)}
                        onChange={(e) => setFieldValue('sub_category', e)}
                      />
                      {errors.sub_category && touched.sub_category && (
                        <div style={{ color: 'red' }}>
                          {errors.sub_category}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel shrink className={classes.labelAsyncSelect}>
                        Nama Satuan *
                      </InputLabel>

                      <AsyncSelect
                        id="unit"
                        margin="normal"
                        name="unit"
                        placeholder="Nama Satuan"
                        loadOptions={(e) => loadOptions(e, 'units')}
                        defaultOptions
                        defaultValue={isEdit && unitOption}
                        values={values.unit}
                        onInputChange={(e) => handleInputChange(e)}
                        onChange={(e) => setFieldValue('unit', e)}
                      />
                      {errors.unit && touched.unit && (
                        <div style={{ color: 'red' }}>{errors.unit}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="barcode"
                        label="Barcode"
                        name="barcode"
                        onChange={handleChange}
                        value={values.barcode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nama Produk"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Tanggal Kedaluwarsa"
                          value={values.expiry_date}
                          onChange={(e) => {
                            const date = dayjs(e).format('YYYY-MM-DD');

                            // setDate({
                            //   value: date,
                            //   error: false,
                            //   errorText: '',
                            // });
                            setFieldValue('expiry_date', date);
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Harga Beli"
                        value={values.purchase_price}
                        onChange={handleChange}
                        name="purchase_price"
                        id="purchase_price"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      {errors.purchase_price && touched.purchase_price && (
                        <div style={{ color: 'red' }}>
                          {errors.purchase_price}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Harga Jual"
                        value={values.selling_price}
                        onChange={handleChange}
                        name="selling_price"
                        id="selling_price"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      {errors.selling_price && touched.selling_price && (
                        <div style={{ color: 'red' }}>
                          {errors.selling_price}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Harga Grosir"
                        value={values.wholesale_price}
                        onChange={handleChange}
                        name="wholesale_price"
                        id="wholesale_price"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      {errors.wholesale_price && touched.wholesale_price && (
                        <div style={{ color: 'red' }}>
                          {errors.wholesale_price}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Stok Awal"
                        value={values.first_stock}
                        onChange={handleChange}
                        name="first_stock"
                        id="first_stock"
                        type="number"
                      />
                      {errors.first_stock && touched.first_stock && (
                        <div style={{ color: 'red' }}>{errors.first_stock}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Stok Akhir"
                        value={values.last_stock}
                        onChange={handleChange}
                        name="last_stock"
                        id="last_stock"
                        type="number"
                      />
                      {errors.last_stock && touched.last_stock && (
                        <div style={{ color: 'red' }}>{errors.last_stock}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Stok Minimal"
                        value={values.minimal_stock}
                        onChange={handleChange}
                        name="minimal_stock"
                        id="minimal_stock"
                        type="number"
                      />
                      {errors.minimal_stock && touched.minimal_stock && (
                        <div style={{ color: 'red' }}>
                          {errors.minimal_stock}
                        </div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/produk')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <AddPage />;
};

export default ProductForm;
