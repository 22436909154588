import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query categories {
    categories {
      id
      cid
      name
      code
      created_at
      updated_at
    }
  }
`;

export const FIND_CATEGORY_BY_CID = gql`
  query findCategoryByCid($cid: String!) {
    findCategoryByCid(cid: $cid) {
      id
      cid
      name
      code
      created_at
      updated_at
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      id
      cid
      name
      code
      created_at
      updated_at
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($cid: String!, $name: String!) {
    updateCategory(cid: $cid, name: $name) {
      id
      cid
      name
      code
      created_at
      updated_at
    }
  }
`;

export const SEARCH_CATEGORIES = gql`
  query searchCategories($name: String!) {
    searchCategories(name: $name) {
      id
      cid
      name
      code
      created_at
      updated_at
    }
  }
`;
