import { gql } from '@apollo/client';

export const CUSTOMERS = gql`
  query customers {
    customers {
      id
      cid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const FIND_CUSTOMER_BY_CID = gql`
  query findCustomerByCid($cid: String!) {
    findCustomerByCid(cid: $cid) {
      id
      cid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($data: InCustomer!) {
    createCustomer(data: $data) {
      id
      cid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($cid: String!, $data: InCustomer!) {
    updateCustomer(cid: $cid, data: $data) {
      id
      cid
      name
      address
      phone
      created_at
      updated_at
    }
  }
`;
