import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import GlobalContext from '../../utils/GlobalContext';
import { ORDERS, DELETE_ORDER } from '../../graphql/Order';
import { SUB_CATEGORIES } from '../../graphql/SubCategory';
import SellingReport from '../Report/SellingReport';
import MyFilterDrawer from '../MyFilterDrawer';
import DialogDelete from './DialogDelete';
import dayjs from 'dayjs';

const OrderHistoryPage = (props) => {
  const { query, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [historyOrders, setHistoryOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [income, setIncome] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  const cookie = new Cookies();
  const id = cookie.get('id');
  const role = cookie.get('role');
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Tanggal', field: 'created_at' },
    {
      title: 'Nama Kasir',
      render: (rowData) => {
        if (rowData?.wholesale)
          return (
            <>
              {rowData?.user?.name}{' '}
              <Tooltip title='Penjualan Grosir' placement='right'>
                <IconButton
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          );

        return <>{rowData?.user?.name}</>;
      },
    },
    {
      title: 'Grand Total',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.grand_total}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    {
      title: 'Total Bayar',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.amount_paid}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    { title: 'Diskon (%)', field: 'discount' },
    {
      title: 'Kembalian',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.change}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    {
      title: 'Cetak Barcode',
      render: (rowData) => {
        return (
          <>
            <SellingReport data={rowData} />
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                setOpenDialog(true);
                setIdOrder(rowData.id);
              }}
            >
              Hapus
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setGlobalLoading(true);

    const getHistoryOrders = async () => {
      const {
        data: { orders },
      } = await query({
        query: ORDERS,
        variables: {
          user_id: role !== 'ADMIN' ? id : null,
        },
        fetchPolicy: 'no-cache',
      });

      setHistoryOrders(orders);
      const income = orders.reduce((n, { grand_total }) => n + grand_total, 0);
      setIncome(income);
    };

    getHistoryOrders();
    setGlobalLoading(false);
  }, [id, query, role, setGlobalLoading]);

  useEffect(() => {
    setGlobalLoading(true);
    const getCategories = async () => {
      const {
        data: { subCategories },
      } = await query({
        query: SUB_CATEGORIES,
        fetchPolicy: 'no-cache',
      });
      setSubCategories(subCategories);
      setGlobalLoading(false);
    };

    getCategories();
  }, [query, setGlobalLoading]);

  const filterOrder = async () => {
    const {
      data: { orders },
    } = await query({
      query: ORDERS,
      variables: {
        start_date: startDate,
        end_date: endDate,
        sub_category_id: subCategory,
      },
      fetchPolicy: 'no-cache',
    });

    setHistoryOrders(orders);
    const income = orders.reduce((n, { grand_total }) => n + grand_total, 0);
    setIncome(income);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    const id = idOrder;

    try {
      setGlobalLoading(true);
      const {
        data: { deleteOrder },
      } = await mutate({
        mutation: DELETE_ORDER,
        variables: { id },
        fetchPolicy: 'no-cache',
        onError: (error) => {
          console.log('==> Error execute mutation', error);
        },
      });
      if (deleteOrder) {
        setGlobalLoading(false);
        setSnack({
          variant: 'success',
          message: 'Data Order Berhasil Dihapus',
          opened: true,
        });

        window.location.reload();
      }
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <MaterialTable
        columns={columns}
        data={historyOrders}
        title={`History Penjualan | Total Pendapatan Rp. ${income.toLocaleString(
          'id-ID',
        )}`}
        detailPanel={(rowData) => {
          return (
            <TableContainer>
              <Table style={{ minWidth: 700 }} aria-label='spanning table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Nama Produk</TableCell>
                    <TableCell align='center'>Harga Jual</TableCell>
                    <TableCell align='center'>Jumlah</TableCell>
                    <TableCell align='center'>Sub Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.order_products.map((x) => (
                    <TableRow key={x.id}>
                      <TableCell align='center'>{x.product.name}</TableCell>
                      <TableCell align='center'>
                        Rp.{' '}
                        {x.price.toLocaleString('id-ID', {
                          minimumFractionDigits: 0,
                        })}
                      </TableCell>
                      <TableCell align='center'>
                        {x.quantity.toLocaleString('id-ID', {
                          minimumFractionDigits: 0,
                        })}
                      </TableCell>
                      <TableCell align='center'>
                        Rp.{' '}
                        {x.sub_total.toLocaleString('id-ID', {
                          minimumFractionDigits: 0,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'filter_list',
            tooltip: 'Filter Data',
            isFreeAction: true,
            onClick: (event) => {
              setOpen(true);
            },
          },
        ]}
      />
      <MyFilterDrawer
        open={open}
        setOpen={setOpen}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        filterOrder={filterOrder}
        subCategories={subCategories}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
      />
      <DialogDelete
        open={openDialog}
        setOpen={setOpenDialog}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default OrderHistoryPage;
