import React, { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import GlobalContext from '../../utils/GlobalContext';
import { PRODUCTS } from '../../graphql/Product';
import MyModal from '../MyModal';
import MyTable from '../MyTable';
import MyBarcode from '../MyBarcode';

const ProductPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [products, setProducts] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = useState(false);
  const cookie = new Cookies();
  const role = cookie.get('role');
  const disableAction = role === 'ADMIN' ? false : true;
  let columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Produk', field: 'sub_category.name' },
    { title: 'Nama', field: 'name' },
    { title: 'Satuan', field: 'unit.name' },
    {
      title: 'Harga Beli',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.purchase_price}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    {
      title: 'Harga Jual',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.selling_price}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    {
      title: 'Harga Grosir',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.wholesale_price}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    { title: 'Stok Awal', field: 'first_stock' },
    { title: 'Stok Akhir', field: 'last_stock' },
    { title: 'Stok Minimal', field: 'minimal_stock' },
    { title: 'Barcode', field: 'barcode', hidden: true, searchable: true },
  ];

  if (role === 'KASIR')
    columns = columns.filter((x) => x.title !== 'Harga Beli');

  if (role === 'ADMIN') {
    columns.push({
      title: 'Cetak Barcode',
      render: (rowData) => {
        if (!rowData.is_barcode && rowData.barcode) {
          return (
            <MyBarcode
              barcode={rowData.barcode}
              name={rowData.name}
              selling_price={rowData.selling_price}
            />
          );
        }

        return;
      },
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setGlobalLoading(true);
    const getCategories = async () => {
      const {
        data: { products },
      } = await query({
        query: PRODUCTS,
        variables: { is_barcode: false },
        fetchPolicy: 'no-cache',
      });
      setProducts(products);
      setGlobalLoading(false);
    };

    getCategories();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={products}
        title='Barang Non Barcode'
        addUrl='/non_barcode/new'
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl='/non_barcode/edit'
        editKey='pid'
        disableAction={disableAction}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type='Produk'
        mappingRows={{
          pid: 'ID',
          barcode: 'Barcode',
          sub_category: {
            title: 'Sub Kategori',
            row: 'name',
          },
          name: 'Nama',
          unit: {
            title: 'Satuan',
            row: 'name',
          },
          purchase_price: 'Harga Beli',
          selling_price: 'Harga Jual',
          wholesale_price: 'Harga Grosir',
          first_stock: 'Stok Awal',
          last_stock: 'Stok Akhir',
          minimal_stock: 'Stok Minimal',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default ProductPage;
