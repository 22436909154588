import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import GlobalContext from '../../utils/GlobalContext';
import { FIND_PRODUCT_BY_SUB_CATEGORY } from '../../graphql/Product';
import { SUB_CATEGORIES } from '../../graphql/SubCategory';
import MyFilterDrawer from '../MyFilterDrawer';
import dayjs from 'dayjs';

const OrderHistoryPage = (props) => {
  const { query } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [historyOrders, setHistoryOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [subCategories, setSubCategories] = useState([]);
  const [income, setIncome] = useState(0);
  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const cookie = new Cookies();
  const id = cookie.get('id');
  const role = cookie.get('role');
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    {
      title: 'Tanggal',
      field: 'created_at',
      render: (rowData) => {
        return dayjs(rowData.created_at).format('DD - MM - YYYY HH:mm:ss');
      },
    },
    {
      title: 'Harga',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.price}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
    {
      title: 'Jumlah',
      render: (rowData) => rowData.quantity,
    },
    {
      title: 'Sub Total',
      render: (rowData) => {
        return (
          <NumberFormat
            value={rowData.sub_total}
            thousandSeparator
            displayType='text'
            prefix='Rp. '
          />
        );
      },
    },
  ];

  useEffect(() => {
    setGlobalLoading(true);

    const getHistoryOrders = async () => {
      const {
        data: { findProductBySubCategory },
      } = await query({
        query: FIND_PRODUCT_BY_SUB_CATEGORY,
        variables: {
          start_date: startDate,
          end_date: endDate,
          sub_category_id: 0,
        },
        fetchPolicy: 'no-cache',
      });
      setHistoryOrders(findProductBySubCategory);
    };

    getHistoryOrders();
    setGlobalLoading(false);
  }, [endDate, id, query, role, setGlobalLoading, startDate]);

  useEffect(() => {
    setGlobalLoading(true);
    const getCategories = async () => {
      const {
        data: { subCategories },
      } = await query({
        query: SUB_CATEGORIES,
        fetchPolicy: 'no-cache',
      });
      setSubCategories(subCategories);
      setGlobalLoading(false);
    };

    getCategories();
  }, [query, setGlobalLoading]);

  const filterOrder = async () => {
    const {
      data: { findProductBySubCategory },
    } = await query({
      query: FIND_PRODUCT_BY_SUB_CATEGORY,
      variables: {
        start_date: startDate,
        end_date: endDate,
        sub_category_id: subCategory,
      },
      fetchPolicy: 'no-cache',
    });
    setSubCategoryName(
      subCategories?.find((x) => x.id === subCategory)?.name ?? '',
    );
    setHistoryOrders(findProductBySubCategory);
    const income = findProductBySubCategory.reduce(
      (n, { sub_total }) => n + sub_total,
      0,
    );
    setIncome(income);
  };

  return (
    <>
      <MaterialTable
        columns={columns}
        data={historyOrders}
        title={`Laporan Sub Kategori ${
          subCategoryName ?? ''
        } | Total Pendapatan Rp. ${income.toLocaleString('id-ID')}`}
        actions={[
          {
            icon: 'filter_list',
            tooltip: 'Filter Data',
            isFreeAction: true,
            onClick: (event) => {
              setOpen(true);
            },
          },
        ]}
      />
      <MyFilterDrawer
        open={open}
        setOpen={setOpen}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        filterOrder={filterOrder}
        subCategories={subCategories}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
      />
    </>
  );
};

export default OrderHistoryPage;
