import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { SUB_CATEGORIES } from '../../graphql/SubCategory';
import MyModal from '../MyModal';
import MyTable from '../MyTable';

const SubCategoryPage = (props) => {
  const { query, history, role } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [subCategories, setSubCategories] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Kategori', field: 'category.name' },
    { title: 'Nama', field: 'name' },
    { title: 'Kode', field: 'code' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      width: 500,
      type: 'datetime',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setGlobalLoading(true);
    const getCategories = async () => {
      const {
        data: { subCategories },
      } = await query({
        query: SUB_CATEGORIES,
        fetchPolicy: 'no-cache',
      });
      setSubCategories(subCategories);
      setGlobalLoading(false);
    };

    getCategories();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={subCategories}
        title="Sub Kategori"
        addUrl="/sub_kategori/new"
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl="/sub_kategori/edit"
        editKey="scid"
        disableAction={role !== 'ADMIN' ? true : false}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Sub Kategori"
        mappingRows={{
          scid: 'ID',
          category: {
            title: 'Kategori',
            row: 'name',
          },
          name: 'Nama',
          code: 'Kode',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default SubCategoryPage;
