import { gql } from '@apollo/client';

export const AUTHENTICATE = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      id
      email
      uid
      role
      token
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($data: InUser!) {
    createUser(data: $data) {
      id
      uid
      name
      email
      role
      created_at
      updated_at
    }
  }
`;

export const USERS = gql`
  query users {
    users {
      id
      uid
      name
      email
      role
      created_at
      updated_at
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($uid: String!, $data: InUser!) {
    updateUser(uid: $uid, data: $data) {
      id
      uid
      name
      email
      role
      created_at
      updated_at
    }
  }
`;

export const FIND_USER_BY_UID = gql`
  query findUserByUid($uid: String!) {
    findUserByUid(uid: $uid) {
      id
      uid
      name
      email
      role
      created_at
      updated_at
    }
  }
`;
