import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { CUSTOMERS } from '../../graphql/Customer';
import MyModal from '../MyModal';
import MyTable from '../MyTable';

const CustomerPage = (props) => {
  const { query, history, role } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [customer, setCustomer] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Nama', field: 'name' },
    { title: 'Alamat', field: 'address' },
    { title: 'No. Handphone', field: 'phone' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      width: 500,
      type: 'datetime',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setGlobalLoading(true);
    const getCustomers = async () => {
      const {
        data: { customers },
      } = await query({
        query: CUSTOMERS,
        fetchPolicy: 'no-cache',
      });
      setCustomer(customers);
      setGlobalLoading(false);
    };

    getCustomers();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={customer}
        title="Pelanggan"
        addUrl="/pelanggan/new"
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl="/pelanggan/edit"
        editKey="cid"
        disableAction={role !== 'ADMIN' ? true : false}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Pelanggan"
        mappingRows={{
          cid: 'ID Pelanggan',
          name: 'Nama',
          address: 'Alamat',
          phone: 'No. Handphone',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default CustomerPage;
