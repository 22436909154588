import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core/';

const DialogPrint = (props) => {
  const { open, handleClose, handleDelete } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle id='draggable-dialog-title'>
          Hapus Data Transaksi
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            apakah ingin menghapus data transaksi?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Tutup
          </Button>
          <Button onClick={handleDelete} variant='contained' color='secondary'>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogPrint;
