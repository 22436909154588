import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { SUPPLIERS } from '../../graphql/Supplier';
import MyModal from '../MyModal';
import MyTable from '../MyTable';

const SupplierPage = (props) => {
  const { query, history, role } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [customer, setSupplier] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
    },
    { title: 'Nama', field: 'name' },
    { title: 'Alamat', field: 'address' },
    { title: 'No. Handphone', field: 'phone' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      width: 500,
      type: 'datetime',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setGlobalLoading(true);
    const getSuppliers = async () => {
      const {
        data: { suppliers },
      } = await query({
        query: SUPPLIERS,
        fetchPolicy: 'no-cache',
      });

      setSupplier(suppliers);
      setGlobalLoading(false);
    };

    getSuppliers();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={customer}
        title="Pemasok"
        addUrl="/pemasok/new"
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl="/pemasok/edit"
        editKey="cid"
        disableAction={role !== 'ADMIN' ? true : false}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Pemasok"
        mappingRows={{
          sid: 'ID',
          name: 'Nama',
          address: 'Alamat',
          phone: 'No. Handphone',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default SupplierPage;
