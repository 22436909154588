import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Cookies from 'universal-cookie';

import ListItem from './ListItem';
import MyBreadcrumbs from '../MyBreadcrumbs';
import { PRODUCTS } from '../../graphql/Product';
import { CREATE_ORDER } from '../../graphql/Order';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';
import DialogPrint from './DialogPrint';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 25,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix='Rp. '
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const TransactionPage = (props) => {
  const { query, mutate } = props;
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState({});
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState({});
  const [form, setForm] = useState({
    product: {
      value: '',
      error: false,
      errorText: '',
    },
    quantity: {
      value: '',
      error: false,
      errorText: '',
    },
    discount: {
      value: '',
      error: false,
      errorText: '',
    },
  });
  const [wholesale, setWholesale] = useState(false);
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const classes = usetStyles();
  const cookies = new Cookies();

  const roundUp = (number, near) => {
    if (number % near === 0) return number;

    return Math.ceil(number / 500) * 500;
  };

  useEffect(() => {
    setGlobalLoading(true);
    const getProducts = async () => {
      const {
        data: { products },
      } = await query({
        query: PRODUCTS,
        fetchPolicy: 'no-cache',
      });

      setProducts(products);
    };

    getProducts();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  const handleChange = async (e, name, newValue) => {
    const oldState = form;

    if (name === 'product') {
      const product = products.find((x) => x.id === newValue.id);

      setForm({
        ...oldState,
        [name]: {
          ...oldState[name],
          value: product.name,
        },
      });

      setTempData({
        id: product.id,
        name: product.name,
        selling_price: product.selling_price,
        last_stock: product.last_stock,
        wholesale_price: product.wholesale_price,
      });
    } else if (name === 'quantity' || name === 'discount') {
      setForm({
        ...oldState,
        [name]: {
          ...oldState[name],
          value: e.target.value,
        },
      });
    }
  };

  const handleAddToCart = () => {
    if (!tempData.name) {
      return;
    }

    if (!form.quantity.value) {
      return;
    }

    const product = data.find((x) => x.id === tempData.id);

    if (product) {
      const additionalStock =
        Number(form.quantity.value) + Number(product.quantity);
      if (Number(additionalStock) > Number(tempData.last_stock)) {
        setSnack({
          variant: 'error',
          message: 'Quantity melebihi stok!',
          opened: true,
        });

        return false;
      }

      const price = wholesale
        ? tempData.wholesale_price
        : tempData.selling_price;
      const quantity = Number(form.quantity.value);
      const discount = Number(form?.discount?.value);
      let sub_total = price;
      let sub_total_before_discount = price;

      if (discount) {
        sub_total_before_discount = price * quantity + product?.price;
        sub_total =
          sub_total_before_discount -
          (sub_total_before_discount * discount) / 100;
      } else {
        sub_total = price * quantity + product?.price;
        sub_total_before_discount = sub_total;
      }

      const sumData = [
        {
          id: tempData.id,
          desc: tempData.name,
          quantity: Number(form.quantity.value) + Number(product.quantity),
          unit: price,
          price: !discount ? sub_total : Math.roundUp(sub_total, 500),
          discount,
          sub_total_before_discount: !discount
            ? sub_total_before_discount
            : roundUp(sub_total_before_discount, 500),
        },
      ];
      const oldState = data.filter((x) => x.id !== tempData.id);

      setData([...sumData, ...oldState]);
      setForm({
        product: {
          value: '',
          error: false,
          errorText: '',
        },
        quantity: {
          value: '',
          error: false,
          errorText: '',
        },
        discount: {
          value: '',
          error: false,
          errorText: '',
        },
      });
      setTempData({
        name: '',
        selling_price: 0,
        last_stock: '',
        wholesale_price: 0,
      });
    } else {
      if (Number(form.quantity.value) > Number(tempData.last_stock)) {
        setSnack({
          variant: 'error',
          message: 'Quantity melebihi stok!',
          opened: true,
        });

        return false;
      }

      const price = wholesale
        ? tempData.wholesale_price
        : tempData.selling_price;
      const quantity = Number(form.quantity.value);
      const discount = Number(form?.discount?.value);
      let sub_total = price;
      let sub_total_before_discount = price;
      if (discount) {
        sub_total_before_discount = price * quantity;
        sub_total =
          sub_total_before_discount -
          (sub_total_before_discount * discount) / 100;
      } else {
        sub_total = price * quantity;
        sub_total_before_discount = sub_total;
      }

      const newData = [
        {
          id: tempData.id,
          desc: tempData.name,
          quantity,
          unit: price,
          price: !discount ? sub_total : roundUp(sub_total, 500),
          discount,
          sub_total_before_discount: !discount
            ? sub_total_before_discount
            : roundUp(sub_total_before_discount, 500),
        },
      ];
      // const newData = [
      //   {
      //     id: tempData.id,
      //     desc: tempData.name,
      //     quantity: form.quantity.value,
      //     unit: wholesale ? tempData.wholesale_price : tempData.selling_price,
      //     price: wholesale
      //       ? tempData.wholesale_price * Number(form.quantity.value)
      //       : tempData.selling_price * Number(form.quantity.value),
      //     discount: form?.discount?.value,
      //   },
      // ];
      setData([...newData, ...data]);
      setForm({
        product: {
          value: '',
          error: false,
          errorText: '',
        },
        quantity: {
          value: '',
          error: false,
          errorText: '',
        },
        discount: {
          value: '',
          error: false,
          errorText: '',
        },
      });
      setTempData({
        name: '',
        selling_price: 0,
        last_stock: '',
        wholesale_price: 0,
      });
    }
  };

  const handeDeleteItem = (id) => {
    setData(data.filter((x) => x.id !== id));
  };

  const handleSave = async (payment, grandTotal, discount) => {
    if (payment < grandTotal) {
      setSnack({
        variant: 'error',
        message: 'Total Bayar tidak boleh kosong',
        opened: true,
      });

      return false;
    }

    const order = {
      amount_paid: Number(payment),
      grand_total: Number(grandTotal),
      change: Number(payment) - Number(grandTotal),
      order_products: data.map((x) => ({
        product_id: x.id,
        price: x.unit,
        quantity: Number(x.quantity),
        sub_total: !x?.discount ? x?.price : roundUp(x?.price, 500),
        sub_total_before_discount: !x?.discount
          ? x?.sub_total_before_discount
          : roundUp(x?.sub_total_before_discount, 500),
        discount: x?.discount,
      })),
      discount: Number(discount),
      user_id: cookies.get('id'),
      wholesale,
    };
    debugger;
    console.log(order);

    try {
      setGlobalLoading(true);
      const {
        data: { createOrder },
      } = await mutate({
        mutation: CREATE_ORDER,
        variables: { data: order },
        fetchPolicy: 'no-cache',
        onError: (error) => {
          console.log('==> Error execute mutation', error);
        },
      });

      if (createOrder) {
        setGlobalLoading(false);
        setSnack({
          variant: 'success',
          message: 'Data Order Berhasil Disimpan',
          opened: true,
        });
        setResult(createOrder);
        // history.replace('/transaksi/new');
        // window.location.reload();
        setOpen(true);
      }
    } catch (error) {
      setGlobalLoading(false);
      setError(setSnack, error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterOptions = createFilterOptions({
    stringify: ({ name, barcode }) => `${name} ${barcode}`,
  });

  const calcSubTotalDiscount = (wholesale, data) => {
    const price = wholesale
      ? Number(tempData?.wholesale_price)
      : Number(tempData?.selling_price);

    if (form?.discount?.value) {
      const discount = Number(form?.discount?.value);
      return price - (price * discount) / 100;
    }

    return price;
  };

  return (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Penjualan', url: '/transaksi/new' }}
        tertiary={{ title: 'Tambah' }}
      />
      <Grid container spacing={0} justify='center'>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  id='combo-box-demo'
                  options={products}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  filterOptions={filterOptions}
                  onChange={(e, newValue) => {
                    // setTimeout(() => {
                    //   debugger;
                    //   handleChange(e, 'product', newValue);
                    // }, 100);
                    handleChange(e, 'product', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Nama Produk atau Barcode'
                      variant='outlined'
                      autoFocus
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  margin='normal'
                  fullWidth
                  id='name'
                  label='Nama Produk'
                  name='name'
                  disabled
                  value={tempData?.name}
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Stok'
                  value={tempData?.last_stock}
                  onChange={handleChange}
                  name='last_stock'
                  id='last_stock'
                  disabled
                  type='number'
                  InputLabelProps={{ shrink: tempData?.name ? true : false }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Quantity'
                  value={form.quantity.value}
                  onChange={(e) => handleChange(e, 'quantity')}
                  name='quantity'
                  id='quantity'
                  type='number'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  margin='normal'
                  fullWidth
                  id='discount'
                  label='Diskon (%)'
                  name='discount'
                  type='number'
                  InputProps={{
                    inputProps: {
                      type: 'number',
                      max: 100,
                      min: 10,
                    },
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value, 10);
                    const min = 0;
                    const max = 100;
                    if (value > max) value = max;
                    if (value < min) value = min;

                    handleChange(e, 'discount');
                  }}
                  value={form.discount.value}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Harga Jual'
                  value={
                    wholesale
                      ? tempData?.wholesale_price
                      : tempData?.selling_price
                  }
                  name='selling_price'
                  id='selling_price'
                  disabled
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Harga Diskon'
                  value={calcSubTotalDiscount(wholesale, tempData)}
                  name='selling_price_discount'
                  id='selling_price_discount'
                  disabled
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={wholesale}
                        onChange={(e) => setWholesale(e.target.checked)}
                        name='checkedA'
                        color='primary'
                        disabled={wholesale || data?.length}
                      />
                    }
                    label='Grosir'
                  />
                </div>
              </Grid>
              <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='baseline'
              >
                <Button
                  onClick={() => handleAddToCart()}
                  variant='outlined'
                  color='primary'
                  style={{
                    marginRight: 10,
                  }}
                  disabled={!form?.product?.value || !form?.quantity?.value}
                >
                  Tambah
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ListItem
            data={data}
            handeDeleteItem={handeDeleteItem}
            NumberFormatCustom={NumberFormatCustom}
            handleSave={handleSave}
          />
        </Grid>
      </Grid>
      <DialogPrint
        open={open}
        data={result}
        handleClose={handleClose}
        setOpen={setOpen}
      />
    </div>
  );
};

export default TransactionPage;
