import { gql } from '@apollo/client';

export const PRODUCTS = gql`
  query products($is_barcode: Boolean) {
    products(is_barcode: $is_barcode) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      is_barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const FIND_PRODUCT_BY_PID = gql`
  query findProductByPid($pid: String!) {
    findProductByPid(pid: $pid) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const SEARCH_PRODUCT = gql`
  query searchProducts($name: String!) {
    searchProducts(name: $name) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: InProduct!) {
    createProduct(data: $data) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($pid: String!, $data: InProduct!) {
    updateProduct(pid: $pid, data: $data) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const FIND_PRODUCT_BY_BARCODE = gql`
  query findProductByBarcode($barcode: String!) {
    findProductByBarcode(barcode: $barcode) {
      id
      sub_category_id
      unit_id
      pid
      name
      purchase_price
      selling_price
      first_stock
      last_stock
      minimal_stock
      created_at
      updated_at
      barcode
      sub_category {
        id
        category_id
        scid
        name
        created_at
        updated_at
      }
      unit {
        id
        unid
        name
        created_at
        updated_at
      }
      wholesale_price
      expiry_date
    }
  }
`;

export const DASHBOARD = gql`
  query dashboard {
    dashboard {
      total_barcode
      total_non_barcode
      total_sales
      total_product_sales {
        name
        total
        id
      }
    }
  }
`;

export const FIND_PRODUCT_BY_SUB_CATEGORY = gql`
  query findProductBySubCategory(
    $sub_category_id: ID!
    $start_date: Date
    $end_date: Date
  ) {
    findProductBySubCategory(
      sub_category_id: $sub_category_id
      start_date: $start_date
      end_date: $end_date
    )
  }
`;
