import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { Tooltip, Collapse } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import {
  ExpandLess,
  ExpandMore,
  Input,
  Dashboard,
  Menu as MenuIcon,
  PowerSettingsNew,
} from '@material-ui/icons';

import DashboardPage from './User/DashboardPage';
import UserPage from './User/UserPage';
import UserForm from './User/UserForm';

import UnitPage from './Master/UnitPage';
import UnitForm from './Master/UnitForm';
import CategoryPage from './Master/CategoryPage';
import CategoryForm from './Master/CategoryForm';
import SubCategoryPage from './Master/SubCategoryPage';
import SubCategoryForm from './Master/SubCategoryForm';
import BarcodeProductPage from './Master/BarcodeProductPage';
import BarcodeProductForm from './Master/BarcodeProductForm';
import NonBarcodeProductPage from './Master/NonBarcodeProductPage';
import NonBarcodeProductForm from './Master/NonBarcodeProductForm';
import TransactionPage from './Transaction/TransactionPage';
import OrderHistoryPage from './Transaction/OrderHistoryPage';
import CustomerForm from './Master/CustomerForm';
import CustomerPage from './Master/CustomerPage';
import SupplierForm from './Master/SupplierForm';
import SupplierPage from './Master/SupplierPage';
import IncomingStockPage from './Stock/IncomingStockPage';
import IncomingStockForm from './Stock/IncomingStockForm';
import OutgoingStockPage from './Stock/OutgoingStockPage';
import OutgoingStockForm from './Stock/OutgoingStockForm';
import ReportSubCategoryPage from './Report/SubCategoryPage';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Admin = (props) => {
  const { container, query, history, mutate } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dropdownMaster, setDropdownMaster] = useState(false);
  const [dropdownTransaction, setDropdownTransaction] = useState(false);
  const [dropdownProduct, setDropdownProduct] = useState(false);
  const [dropdownStock, setDropdownStock] = useState(false);
  const [dropdownReport, setDropdownReport] = useState(false);
  const cookie = new Cookies();
  const role = cookie.get('role');

  const logout = () => {
    cookie.remove('id');
    cookie.remove('name');
    cookie.remove('token', { path: '/' });
    history.replace('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let drawer = null;

  if (role === 'ADMIN') {
    drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <Tooltip title='Dashboard'>
            <ListItem component={Link} to='/dashboard' button key='Dashboard'>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItem>
          </Tooltip>
          <Tooltip title='Master'>
            <ListItem button onClick={() => setDropdownMaster(!dropdownMaster)}>
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Master' />
              {dropdownMaster ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownMaster} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Satuan'>
                <ListItem component={Link} to='/satuan' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Satuan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Kategori'>
                <ListItem component={Link} to='/kategori' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Kategori' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Sub Kategori'>
                <ListItem component={Link} to='/sub_kategori' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Sub Kategori' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Pelanggan'>
                <ListItem component={Link} to='/pelanggan' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Pelanggan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Pengguna'>
                <ListItem component={Link} to='/pengguna' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Pengguna' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Pemasok'>
                <ListItem component={Link} to='/pemasok' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Pemasok' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>

          <Tooltip title='Barang'>
            <ListItem
              button
              onClick={() => setDropdownProduct(!dropdownProduct)}
            >
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Barang' />
              {dropdownProduct ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownProduct} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Barcode'>
                <ListItem component={Link} to='/produk' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Barcode' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Non Barcode'>
                <ListItem component={Link} to='/non_barcode' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Non Barcode' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Tooltip title='Transaksi'>
            <ListItem
              button
              onClick={() => setDropdownTransaction(!dropdownTransaction)}
            >
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Transaksi' />
              {dropdownTransaction ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownTransaction} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Penjualan'>
                <ListItem component={Link} to='/transaksi/new' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Penjualan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Histori Penjualan'>
                <ListItem component={Link} to='/transaksi/histori' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Histori Penjualan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          {/* MENU STOCK OPNAME */}
          <Tooltip title='Stok Opname'>
            <ListItem button onClick={() => setDropdownStock(!dropdownStock)}>
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Stok Opname' />
              {dropdownStock ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownStock} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Barang Masuk'>
                <ListItem component={Link} to='/incoming_stock' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Barang Masuk' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Barang Keluar'>
                <ListItem component={Link} to='/outgoing_stock' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Barang Keluar' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          {/* MENU STOCK LAPORAN */}
          <Tooltip title='Laporan'>
            <ListItem button onClick={() => setDropdownReport(!dropdownReport)}>
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Laporan' />
              {dropdownReport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownReport} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Barang Masuk'>
                <ListItem component={Link} to='/incoming_stock' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Barang Masuk' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Sub Kategori'>
                <ListItem component={Link} to='/report_sub_category' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Sub Kategori' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider />
          <List>
            <Tooltip title='Log Out'>
              <ListItem button key='Logout' onClick={logout}>
                <ListItemIcon>
                  <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText primary='Log Out' />
              </ListItem>
            </Tooltip>
          </List>
        </List>
        <Divider />
      </div>
    );
  } else if (role === 'KASIR') {
    drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <Tooltip title='Dashboard'>
            <ListItem component={Link} to='/dashboard' button key='Dashboard'>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItem>
          </Tooltip>
          <Tooltip title='Barang'>
            <ListItem
              button
              onClick={() => setDropdownProduct(!dropdownProduct)}
            >
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Barang' />
              {dropdownProduct ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownProduct} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Barcode'>
                <ListItem component={Link} to='/produk' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Barcode' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Non Barcode'>
                <ListItem component={Link} to='/non_barcode' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Non Barcode' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Tooltip title='Transaksi'>
            <ListItem
              button
              onClick={() => setDropdownTransaction(!dropdownTransaction)}
            >
              <ListItemIcon>
                <Input />
              </ListItemIcon>
              <ListItemText primary='Transaksi' />
              {dropdownTransaction ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={dropdownTransaction} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <Tooltip title='Penjualan'>
                <ListItem component={Link} to='/transaksi/new' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Penjualan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
            <List component='div' disablePadding>
              <Tooltip title='Histori Penjualan'>
                <ListItem component={Link} to='/transaksi/histori' button>
                  <Typography noWrap>
                    <ListItemText inset primary='Histori Penjualan' />
                  </Typography>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <List>
            <Tooltip title='Log Out'>
              <ListItem button key='Logout' onClick={logout}>
                <ListItemIcon>
                  <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText primary='Log Out' />
              </ListItem>
            </Tooltip>
          </List>
        </List>
        <Divider />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            Toko {process.env.REACT_APP_NAME} | Login as: {role}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            exact
            path='/dashboard'
            render={(props) => <DashboardPage query={query} {...props} />}
          />
          <Route
            exact
            path='/user'
            render={(props) => <UserPage query={query} {...props} />}
          />

          <Route
            exact
            path='/satuan'
            render={(props) => (
              <UnitPage query={query} mutate={mutate} {...props} role={role} />
            )}
          />

          <Route
            exact
            path='/satuan/new'
            render={(props) => (
              <UnitForm query={query} mutate={mutate} {...props} />
            )}
          />

          <Route
            exact
            path='/satuan/edit/:unid'
            render={(props) => (
              <UnitForm query={query} mutate={mutate} isEdit {...props} />
            )}
          />

          <Route
            exact
            path='/kategori'
            render={(props) => (
              <CategoryPage query={query} role={role} {...props} />
            )}
          />

          <Route
            exact
            path='/kategori/new'
            render={(props) => (
              <CategoryForm query={query} mutate={mutate} {...props} />
            )}
          />

          <Route
            exact
            path='/kategori/edit/:cid'
            render={(props) => (
              <CategoryForm query={query} mutate={mutate} isEdit {...props} />
            )}
          />

          <Route
            exact
            path='/sub_kategori'
            render={(props) => (
              <SubCategoryPage query={query} role={role} {...props} />
            )}
          />
          <Route
            exact
            path='/sub_kategori/new'
            render={(props) => (
              <SubCategoryForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/sub_kategori/edit/:scid'
            render={(props) => (
              <SubCategoryForm
                query={query}
                mutate={mutate}
                isEdit
                {...props}
              />
            )}
          />

          <Route
            exact
            path='/produk'
            render={(props) => <BarcodeProductPage query={query} {...props} />}
          />
          <Route
            exact
            path='/produk/new'
            render={(props) => (
              <BarcodeProductForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/produk/edit/:pid'
            render={(props) => (
              <BarcodeProductForm
                query={query}
                mutate={mutate}
                isEdit
                {...props}
              />
            )}
          />

          <Route
            exact
            path='/transaksi/new'
            render={(props) => (
              <TransactionPage query={query} mutate={mutate} {...props} />
            )}
          />

          <Route
            exact
            path='/pelanggan'
            render={(props) => (
              <CustomerPage
                query={query}
                mutate={mutate}
                role={role}
                {...props}
              />
            )}
          />

          <Route
            exact
            path='/pelanggan/new'
            render={(props) => (
              <CustomerForm query={query} mutate={mutate} {...props} />
            )}
          />

          <Route
            exact
            path='/pelanggan/edit/:cid'
            render={(props) => (
              <CustomerForm query={query} mutate={mutate} isEdit {...props} />
            )}
          />

          <Route
            exact
            path='/pengguna'
            render={(props) => (
              <UserPage query={query} mutate={mutate} role={role} {...props} />
            )}
          />

          <Route
            exact
            path='/pengguna/new'
            render={(props) => (
              <UserForm query={query} mutate={mutate} {...props} />
            )}
          />

          <Route
            exact
            path='/pengguna/edit/:uid'
            render={(props) => (
              <UserForm query={query} mutate={mutate} isEdit {...props} />
            )}
          />

          <Route
            exact
            path='/transaksi/histori'
            render={(props) => (
              <OrderHistoryPage query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/non_barcode'
            render={(props) => (
              <NonBarcodeProductPage query={query} {...props} />
            )}
          />
          <Route
            exact
            path='/non_barcode/new'
            render={(props) => (
              <NonBarcodeProductForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/non_barcode/edit/:pid'
            render={(props) => (
              <NonBarcodeProductForm
                query={query}
                mutate={mutate}
                isEdit
                {...props}
              />
            )}
          />
          <Route
            exact
            path='/pemasok'
            render={(props) => (
              <SupplierPage query={query} role={role} {...props} />
            )}
          />
          <Route
            exact
            path='/pemasok/new'
            render={(props) => (
              <SupplierForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/pemasok/edit/:sid'
            render={(props) => (
              <SupplierForm query={query} mutate={mutate} isEdit {...props} />
            )}
          />
          <Route
            exact
            path='/incoming_stock'
            render={(props) => <IncomingStockPage query={query} {...props} />}
          />
          <Route
            exact
            path='/incoming_stock/new'
            render={(props) => (
              <IncomingStockForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/outgoing_stock'
            render={(props) => <OutgoingStockPage query={query} {...props} />}
          />
          <Route
            exact
            path='/outgoing_stock/new'
            render={(props) => (
              <OutgoingStockForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path='/report_sub_category'
            render={(props) => (
              <ReportSubCategoryPage query={query} mutate={mutate} {...props} />
            )}
          />
          <Redirect exact from='/' to='/dashboard' />
        </Switch>
      </main>
    </div>
  );
};

Admin.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === 'undefined' ? Object : Element,
  ),
};

export default Admin;
