import { gql } from '@apollo/client';

export const SUB_CATEGORIES = gql`
  query subCategories {
    subCategories {
      id
      category_id
      scid
      code
      name
      created_at
      updated_at
      category {
        id
        cid
        name
        created_at
        updated_at
      }
    }
  }
`;

export const FIND_SUB_CATEGORY_BY_SCID = gql`
  query findSubCategoryByScid($scid: String!) {
    findSubCategoryByScid(scid: $scid) {
      id
      category_id
      scid
      name
      code
      created_at
      updated_at
      category {
        id
        cid
        name
        created_at
        updated_at
      }
    }
  }
`;

export const CREATE_SUB_CATEGORY = gql`
  mutation createSubCategory($category_id: ID!, $name: String!) {
    createSubCategory(category_id: $category_id, name: $name) {
      id
      category_id
      scid
      name
      code
      created_at
      updated_at
      category {
        id
        cid
        name
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_SUB_CATEGORY = gql`
  mutation updateSubCategory(
    $scid: String!
    $category_id: ID!
    $name: String!
  ) {
    updateSubCategory(scid: $scid, category_id: $category_id, name: $name) {
      id
      category_id
      scid
      name
      code
      created_at
      updated_at
      category {
        id
        cid
        name
        created_at
        updated_at
      }
    }
  }
`;

export const SEARCH_SUB_CATEGORIES = gql`
  query searchSubCategories($name: String!) {
    searchSubCategories(name: $name) {
      id
      scid
      name
      code
      created_at
      updated_at
    }
  }
`;
