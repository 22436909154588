import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyBreadcrumbs from '../MyBreadcrumbs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CREATE_USER, FIND_USER_BY_UID, UPDATE_USER } from '../../graphql/User';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const UserForm = (props) => {
  const { history, query, mutate, match, isEdit } = props;
  const classes = usetStyles();
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [user, setUser] = useState({});
  const roles = ['ADMIN', 'KASIR', 'GROSIR'];

  useEffect(() => {
    if (isEdit && match.params.uid) {
      setGlobalLoading(true);
      const getUnit = async () => {
        const {
          data: { findUserByUid },
        } = await query({
          query: FIND_USER_BY_UID,
          variables: {
            uid: match.params.uid,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setUser(findUserByUid);
      };

      getUnit();
    }
  }, [isEdit, match.params.uid, query, setGlobalLoading]);

  const AddPage = () => (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Pengguna', url: '/pengguna' }}
        tertiary={{ title: isEdit ? 'Ubah' : 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              name: isEdit ? user.name : '',
              email: isEdit ? user.email : '',
              password: isEdit ? user.password : '',
              role: isEdit ? user.role : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
              email: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async (
              { name, email, password, role },
              { resetForm }
            ) => {
              try {
                setGlobalLoading(true);

                if (isEdit) {
                  const {
                    data: { updateUser },
                  } = await mutate({
                    mutation: UPDATE_USER,
                    variables: {
                      uid: user.uid,
                      data: {
                        name,
                        email,
                        password,
                        role,
                      },
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });
                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Pengguna ${updateUser.name} berhasil diubah!`,
                    opened: true,
                  });
                } else {
                  const {
                    data: { createUser },
                  } = await mutate({
                    mutation: CREATE_USER,
                    variables: {
                      data: {
                        name,
                        email,
                        password,
                        role,
                      },
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Pengguna ${createUser.name} berhasil disimpan!`,
                    opened: true,
                  });
                }

                history.replace('/pengguna');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nama Pengguna"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div style={{ color: 'red' }}>{errors.email}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <div style={{ color: 'red' }}>{errors.password}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="tingkat">Peran</InputLabel>
                        <Select
                          autoFocus
                          margin="normal"
                          fullWidth
                          id="role"
                          label="Peran"
                          name="role"
                          onChange={handleChange}
                          value={values.role}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {roles.length
                            ? roles.map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      {errors.role && touched.role && (
                        <div style={{ color: 'red' }}>{errors.role}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/pengguna')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <AddPage />;
};

export default UserForm;
