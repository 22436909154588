import { gql } from '@apollo/client';

export const CREATE_INCOMING_STOCK = gql`
  mutation createIncomingStock($data: InIncomingStock!) {
    createIncomingStock(data: $data) {
      product_id
      supplier_id
      price
      stock_quantity
      notes
      history
      created_at
      updated_at
      date
    }
  }
`;

export const INCOMING_STOCKS = gql`
  query incomingStocks {
    incomingStocks {
      id
      product_id
      supplier_id
      price
      stock_quantity
      notes
      history
      created_at
      updated_at
      date
      product {
        id
        name
        last_stock
      }
      supplier {
        id
        name
      }
    }
  }
`;
