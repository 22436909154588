import React from 'react';
import Barcode from 'react-barcode';

export class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="print-source">
        <table align="center" style={{ marginTop: 0, fontFamily: 'courier' }}>
          <tr width="10">
            <td>
              <p style={{ fontSize: '16px', margin: 0 }}>{this.props.name}</p>
            </td>
          </tr>
          <tr width="10" style={{ margin: 0 }}>
            <td
              style={{
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Barcode
                value={this.props.barcode}
                width={1.3}
                height={25}
                textAlign="center"
                textPosition="bottom"
                flat={true}
                fontSize={8}
                marginTop={2}
                marginRight={1}
                marginLeft={1}
                margin={0}
              />
            </td>
          </tr>
          <tr width="10">
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <p style={{ fontSize: '16px', margin: 0 }}>
                {this.props.selling_price.toLocaleString('id-ID')}
              </p>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}
