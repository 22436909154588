import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, TextField, Button, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyBreadcrumbs from '../MyBreadcrumbs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import {
  CREATE_SUB_CATEGORY,
  FIND_SUB_CATEGORY_BY_SCID,
  UPDATE_SUB_CATEGORY,
} from '../../graphql/SubCategory';
import { SEARCH_CATEGORIES } from '../../graphql/Category';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';

const usetStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const SubCategoryForm = (props) => {
  const { history, query, mutate, match, isEdit } = props;
  const classes = usetStyles();
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [subCategory, setSubCategory] = useState({});
  let customOptions = [];

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };

  const getCategories = async ({ name }) => {
    const {
      data: { searchCategories },
    } = await query({
      query: SEARCH_CATEGORIES,
      variables: { name },
      fetctPolicy: 'no-cache',
    });

    const options = searchCategories.map((l) => ({
      value: l.id,
      label: l.name,
    }));

    return options;
  };

  const loadOptions = async (inputValue) => {
    return getCategories({
      name: inputValue,
    });
  };

  useEffect(() => {
    if (isEdit && match.params.scid) {
      setGlobalLoading(true);
      const getUnit = async () => {
        const {
          data: { findSubCategoryByScid },
        } = await query({
          query: FIND_SUB_CATEGORY_BY_SCID,
          variables: {
            scid: match.params.scid,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setSubCategory(findSubCategoryByScid);
      };

      getUnit();
    }
  }, [isEdit, match.params.scid, query, setGlobalLoading]);

  if (isEdit) {
    customOptions.push({
      value: subCategory?.category?.id,
      label: subCategory?.category?.name,
    });
  }
  console.log(customOptions, 'customOptions');

  const AddPage = () => (
    <div>
      <MyBreadcrumbs
        primary={{ title: 'Beranda', url: '/' }}
        secondary={{ title: 'Sub Kategori', url: '/sub_kategori' }}
        tertiary={{ title: isEdit ? 'Ubah' : 'Tambah' }}
      />
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              name: isEdit ? subCategory.name : '',
              category_id: isEdit ? subCategory.category_id : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({ name, category_id }, { resetForm }) => {
              try {
                setGlobalLoading(true);

                if (isEdit) {
                  const cat_id =
                    typeof category_id === 'object'
                      ? category_id.value
                      : category_id;

                  const {
                    data: { updateSubCategory },
                  } = await mutate({
                    mutation: UPDATE_SUB_CATEGORY,
                    variables: {
                      scid: subCategory.scid,
                      category_id: cat_id,
                      name,
                    },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Sub Kategori ${updateSubCategory.name} berhasil diubah!`,
                    opened: true,
                  });
                } else {
                  const {
                    data: { createSubCategory },
                  } = await mutate({
                    mutation: CREATE_SUB_CATEGORY,
                    variables: { name, category_id: category_id.value },
                    fetchPolicy: 'no-cache',
                    onError: (error) => {
                      console.log('==> Error execute mutation', error);
                    },
                  });

                  setGlobalLoading(false);
                  setSnack({
                    variant: 'success',
                    message: `Data Sub Kategori ${createSubCategory.name} berhasil disimpan!`,
                    opened: true,
                  });
                }

                history.replace('/sub_kategori');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
            }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={11} sm={11} md={12} lg={12}>
                      <InputLabel shrink className={classes.labelAsyncSelect}>
                        Nama Kategori *
                      </InputLabel>

                      <AsyncSelect
                        id="category_id"
                        margin="normal"
                        name="category_id"
                        placeholder="Nama Kategori"
                        loadOptions={(e) => loadOptions(e)}
                        defaultOptions
                        defaultValue={isEdit && customOptions}
                        values={values.category_id}
                        onInputChange={(e) => handleInputChange(e)}
                        onChange={(e) => setFieldValue('category_id', e)}
                      />
                      {errors.category_id && touched.category_id && (
                        <div style={{ color: 'red' }}>{errors.category_id}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nama Sub Kategori"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div style={{ color: 'red' }}>{errors.name}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/sub_kategori')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );

  return <AddPage />;
};

export default SubCategoryForm;
