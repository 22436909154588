import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder($data: InOrder!) {
    createOrder(data: $data) {
      id
      oid
      amount_paid
      grand_total
      discount
      change
      created_at
      updated_at
      user {
        id
        name
      }
      order_products {
        price
        quantity
        sub_total
        discount
        sub_total_before_discount
        discount_price
        product {
          id
          sub_category_id
          unit_id
          pid
          name
          purchase_price
          selling_price
          first_stock
          last_stock
          minimal_stock
          created_at
          updated_at
          unit {
            name
          }
        }
      }
    }
  }
`;

export const ORDERS = gql`
  query orders(
    $user_id: ID
    $start_date: Date
    $end_date: Date
    $sub_category_id: ID
  ) {
    orders(
      user_id: $user_id
      start_date: $start_date
      end_date: $end_date
      sub_category_id: $sub_category_id
    ) {
      id
      oid
      amount_paid
      grand_total
      discount
      change
      created_at
      updated_at
      wholesale
      user {
        id
        name
      }
      order_products {
        price
        quantity
        sub_total
        discount
        sub_total_before_discount
        discount_price
        product {
          id
          sub_category_id
          unit_id
          pid
          name
          purchase_price
          selling_price
          first_stock
          last_stock
          minimal_stock
          created_at
          updated_at
          unit {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id)
  }
`;
