import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@material-ui/core';

import { SellingReportPDF } from './SellingReportPDF';

const Example = (props) => {
  const { data } = props;
  const componentRef = useRef();
//   const pageStyle = `
//     @page {
//       size: 147.40mm 209.76mm;
//       margin: 0mm !important
//     }
//   `;

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" color="primary">
            Cetak
          </Button>
        )}
        content={() => componentRef.current}
        documentTitle={`Cetak Barcode`}
      />
      <SellingReportPDF data={data} ref={componentRef} />
    </div>
  );
};

export default Example;
