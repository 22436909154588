import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Delete as DeleteIcon } from '@material-ui/icons';
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const grandTotal = (items, discount) => {
  let gt = items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  const totalDiscount = gt * (discount / 100);
  gt -= totalDiscount;

  return gt;
};

export default function ListItem(props) {
  const { data, handeDeleteItem, NumberFormatCustom, handleSave } = props;
  const [payment, setPayment] = useState(0);
  const [discount, setDiscount] = useState(0);
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='spanning table'>
        <TableHead>
          <TableRow>
            <TableCell align='center' colSpan={5}>
              Item
            </TableCell>
            <TableCell align='right'>Total</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' style={{ width: 20 }}>
              *
            </TableCell>
            <TableCell>Nama Produk</TableCell>
            <TableCell align='right'>Harga Jual</TableCell>
            <TableCell align='right'>Jumlah</TableCell>
            <TableCell align='right'>Diskon</TableCell>
            <TableCell align='right'>Sub Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align='right'>
                <Tooltip title='Hapus'>
                  <IconButton
                    onClick={() => handeDeleteItem(row.id)}
                    aria-label='actions'
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>{row.desc}</TableCell>
              <TableCell align='right'>
                Rp.{' '}
                {row.unit.toLocaleString('id-ID', {
                  minimumFractionDigits: 0,
                })}
              </TableCell>
              <TableCell align='right'>
                {row.quantity.toLocaleString('id-ID', {
                  minimumFractionDigits: 0,
                })}
              </TableCell>
              <TableCell align='right'>
                {row.discount.toLocaleString('id-ID', {
                  minimumFractionDigits: 0,
                })}
                %
              </TableCell>
              <TableCell align='right'>
                {row?.discount !== 0 && (
                  <p style={{ textDecoration: 'line-through' }}>
                    Rp.{' '}
                    {row.sub_total_before_discount.toLocaleString('id-ID', {
                      minimumFractionDigits: 0,
                    })}
                  </p>
                )}{' '}
                Rp.{' '}
                {row.price.toLocaleString('id-ID', {
                  minimumFractionDigits: 0,
                })}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={4} />
            <TableCell colSpan={4}>Grand Total</TableCell>
            <TableCell align='right'>
              Rp.{' '}
              {grandTotal(data, discount).toLocaleString('id-ID', {
                minimumFractionDigits: 0,
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>Total Bayar</TableCell>
            <TableCell align='right'>
              <TextField
                margin='normal'
                fullWidth
                id='name'
                label='Total Bayar'
                name='name'
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => setPayment(e.target.value)}
                value={payment}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align='right'>
              <Button
                onClick={() =>
                  handleSave(payment, grandTotal(data, discount), discount)
                }
                variant='contained'
                color='primary'
                style={{
                  marginRight: 10,
                }}
                disabled={data.length ? false : true}
              >
                Simpan
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
