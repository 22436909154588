import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 250,
    marginLeft: 10,
    marginRight: 10,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const {
    open,
    setOpen,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    filterOrder,
    subCategories,
    subCategory,
    setSubCategory,
  } = props;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Tanggal Awal"
              value={startDate}
              onChange={(e) => setStartDate(dayjs(e).format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
              disableFuture
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Tanggal Akhir"
              value={endDate}
              onChange={(e) => setEndDate(dayjs(e).format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
              disableFuture
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="subCategory">Sub Kategori</InputLabel>
            <Select
              autoFocus
              margin="normal"
              fullWidth
              id="subCategory"
              label="Sub Kategori"
              name="subCategory"
              onChange={(e) => setSubCategory(e.target.value)}
              value={subCategory}
            >
              <MenuItem value="" disabled>
                --- Pilih ---
              </MenuItem>
              <MenuItem value="">Semua</MenuItem>
              {subCategories.length
                ? subCategories.map((x) => (
                    <MenuItem value={x.id} key={x.id}>
                      {x.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
              filterOrder();
            }}
          >
            Cari
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
