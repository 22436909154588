import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function MyLoading() {
  return (
    <LinearProgress
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 10000,
      }}
    />
  );
}
