import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../utils/GlobalContext';
import { CATEGORIES } from '../../graphql/Category';
import MyModal from '../MyModal';
import MyTable from '../MyTable';

const CategoryPage = (props) => {
  const { query, history, role } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [categories, setCategories] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const columns = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
      width: 100,
    },
    { title: 'Nama', field: 'name' },
    { title: 'Kode', field: 'code' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      width: 500,
      type: 'datetime',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setGlobalLoading(true);
    const getCategories = async () => {
      const {
        data: { categories },
      } = await query({
        query: CATEGORIES,
        fetchPolicy: 'no-cache',
      });
      setCategories(categories);
      setGlobalLoading(false);
    };

    getCategories();
  }, [query, setGlobalLoading]);

  return (
    <div>
      <MyTable
        history={history}
        columns={columns}
        data={categories}
        title="Kategori"
        addUrl="/kategori/new"
        setOpen={setOpen}
        setTempData={setTempData}
        editUrl="/kategori/edit"
        editKey="cid"
        disableAction={role !== 'ADMIN' ? true : false}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Kategori"
        mappingRows={{
          cid: 'ID',
          name: 'Nama',
          code: 'Kode',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </div>
  );
};

export default CategoryPage;
